
const HotelMapModalValues = {
    state: {
        hotelMapModalIsActive: false,
        mapModalSearchData: null
    },

    mutations: {
        setHotelMapModalActiveStatus(state, status) {
            state.hotelMapModalIsActive = status;
        },

        setMapModalSearchData(state, data) {
            state.mapModalSearchData = data;
        },
    },

    getters: {
        _getHotelMapModalActiveStatus(state) {
            return state.hotelMapModalIsActive;
        },

        _getMapModalSearchData(state) {
            return state.mapModalSearchData;
        },
    },
}

export default HotelMapModalValues;
