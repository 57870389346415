import GlobalAxios from "@utils/Api/GlobalAxios.js";
import store from "@store/Store.js";
import helpers from "@helpers/Index.js";
import {createSendDataForListPage} from "@helpers/ListPages/Hotel.js";
import {createRoomFilters} from "@utils/Filters/Hotel/Rooms/Create.js";
import handleRoomFilter from "@utils/Filters/Hotel/Rooms/Filter.js";
import UserBeforeSearch from "@helpers/LocalStorage/UserBeforeSearch.js";
import createSearchFormValuesWithSearchData from "@helpers/ListPages/CreateSearchForm.js";

const getRooms = () => {
    setHotelRoomsAndActivityId()
    const searchData = handleSearchData()

    if (searchData) {
        checkAndSetUrl(searchData)
        UserBeforeSearch.addRoomSearch(store.getters._getHotelTabSearchForm)
    }

    if (store.getters._getAgentCode) searchData.agent_code = store.getters._getAgentCode

    return GlobalAxios.Api.post('hotel/availability/rooms', searchData)
        .then(response => handleGetRoomsSuccess(response))
        .catch(error => handleGetRoomsError(error))
        .finally(handleGetRoomsFinally)
}

const handleGetRoomsSuccess = (response) => {
    if (response.data.data.rooms.length > 0){
        store.commit('setTargetActivityId', response?.data?.data?.id || null)
        store.commit('setPageProps', {
            ...store.getters._getPageProps,
            searchForm: response.data.data.search
        })
        createSearchFormValuesWithSearchData('hotel')
        setHotelRoomsAndActivityId(
            response.data.data.rooms,
            response.data.data.id
        )
    }
    else{
        setHotelRoomsAndActivityId()
        store.commit('setApiErrorValue', [{
            title: 'Uygun Oda Bulunamadı',
            messages: [
                'Aradığınız tarihte konuk sayısına göre uygun oda bulunamadı.',
                'Lütfen tarihleri ve konuk sayısını değiştirerek tekrar deneyiniz.'
            ],
            callCenterMessage: '',
            modalType: 'warning',
        }])
    }

    return true
}

const handleGetRoomsError = (error) => {
    helpers.apiErrorHanding.errorHandle(error)
    setHotelRoomsAndActivityId()
    return false
}

const handleGetRoomsFinally = () => {
    store.commit('setSelectedRooms', [])
    if (store.getters._getHotelRooms?.length){
        const roomFilters = createRoomFilters()
        const searchGuestSize = findSearchGuestSize()
        let isHandleFilter = false
        if (roomFilters){
            roomFilters.forEach(filter => {
                if (filter.type === "ADULT_COUNT"){
                    filter.filters.forEach(filterItem => {filterItem.value = searchGuestSize.includes(filterItem.name)})
                    if (filter.filters.find(filterItem => filterItem.value)) {
                        isHandleFilter = true
                    }
                    else filter.filters.forEach(filterItem => {filterItem.value = true})
                }
            })
        }
        store.commit('setHotelRoomsFilters', roomFilters)
        if (isHandleFilter) handleRoomFilter()
    }
}

const findSearchGuestSize = (searchGuestSize = []) => {
    store.getters._getHotelTabSearchForm.guestSize.forEach(guest => {
        const {adultValue, childrenAges} = guest

        if (!searchGuestSize.includes(adultValue))
            searchGuestSize.push(adultValue)

        const rules = store.getters._getHotelDetails?.rules

        if(childrenAges?.length && rules?.max_free_age){
            const notChildCount = childrenAges.filter(child => child >= rules.max_free_age)?.length || 0
            if (!searchGuestSize.includes(notChildCount + adultValue))
                searchGuestSize.push(notChildCount + adultValue)
        }
    })
    return searchGuestSize
}

const handleSearchData = () => {
    const searchData = createSendDataForListPage()
    delete searchData.location_id
    searchData.hotel_id = store.getters._getHotelDetails.id
    if (store.getters._getPageProps?.searchForm?.id)
        searchData.id = store.getters._getPageProps.searchForm.id

    return searchData
}

const setHotelRoomsAndActivityId = (rooms = null, activityIn = null) => {
    store.commit('setHotelRooms', rooms)
    store.commit('setHotelActivityId', activityIn)
}

const checkAndSetUrl = (searchData) => {
    const tempSearchForm = store.getters._getHotelTabSearchForm
    tempSearchForm.guestSize = Object.values(searchData.rooms)
        .reduce((acc, item) => (acc.push({ adultValue: item.adults, childrenAges: item.children_ages }), acc), [])
    tempSearchForm.dateValues = {
        departureDate: helpers.convertStringDateToObjectDate(searchData.start_date),
        landingDate: helpers.convertStringDateToObjectDate(searchData.end_date)
    }
    store.commit('setTicketTabsSearchForm', {searchForm: tempSearchForm, pageType: 'hotel'})

    setHistoryStateWithSearchData(searchData)

    const newParameters = createUrlWithQueryParameters(searchData)

    if (typeof window !== "undefined" && !window.frameElement && setURLSearchParams(window.location.search) !== setURLSearchParams(newParameters)){
        const url = window.location.href.split('?')[0] + newParameters
        window.history.pushState(window.history.state, '', url)
        if (helpers.isIosChromeAndEdgeBrowser()){
            window.history.replaceState(window.history.state, null, url)
        }
    }
}

const setURLSearchParams = (data) => {
    const targetUrlParams = helpers.getQueryParams(data)
    delete targetUrlParams.id
    delete targetUrlParams.location_id
    return helpers.flattenParams('', targetUrlParams, true)
}

const setHistoryStateWithSearchData = (searchData) => {
    if (window?.history?.state?.props){
        window.history.state.props.searchInformation.rooms = Object.values(searchData.rooms)
        window.history.state.props.searchInformation.start_date = searchData.start_date
        window.history.state.props.searchInformation.end_date = searchData.end_date
    }
}

const createUrlWithQueryParameters = (searchData) => {
    const dates = `start_date=${searchData.start_date}&end_date=${searchData.end_date}`
    let rooms = '';
    Object.entries(searchData.rooms).forEach(([key, value]) => {
        rooms += `&rooms[${key}][adults]=${value.adults}`
        value.children_ages.forEach((age) => {
            if (age) rooms += `&rooms[${key}][children_ages][]=${age}`
        })
    })

    const id = searchData.id ? `&id=${searchData.id}` : ''

    return `?${dates}${rooms}&location_id=${searchData.hotel_id}${id}`
}

export default getRooms;
