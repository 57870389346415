import store from "@store/Store.js";

const isHasStorage = typeof localStorage !== 'undefined'

const getStoragePassengersByPageType = (pageType) => {
    return Object.entries(localStorage)
        .filter(([key]) => key.startsWith(`${pageType}-passenger-`))
        .reduce((acc, [key, value]) => {
            const passengerKeys = key.replace(`${pageType}-passenger-`, '').split('-')
            let currentObj = acc

            passengerKeys.forEach((key, index, array) => {
                if (!currentObj[key]) {
                    currentObj[key] = index === array.length - 1 ? value : {}
                }
                currentObj = currentObj[key]
            });

            return acc
        }, {});
}

const setStoragePassengersValuesInPassengerListForFlight = () => {
    const passengerList = store.getters._getFlightPassengersFormValues
    if (!isHasStorage && !passengerList) return

    const storagePassengers = getStoragePassengersByPageType('flight')
    passengerList.forEach((passenger, passengerIndex) => {
        if(storagePassengers.hasOwnProperty(passenger.group) && storagePassengers[passenger.group][passengerIndex]){
            Object.entries(storagePassengers[passenger.group][passengerIndex]).forEach(([key, value]) => {
                passenger.info[key].value = value
                passenger.info[key].result = true
                if (key === 'identityNumberProps' && value === ''){
                    passenger.info[key].isNotTurkishPeople = true
                }
            })
        }
    })

    store.commit('setFlightReservationPassengersFormValues', [...passengerList])
}

const setFlightPassengersInLocaleStorage = () => {
    if (!isHasStorage) return

    const passengerList = store.getters._getFlightPassengersFormValues
    passengerList.forEach((passenger, index) => {
        Object.keys(passenger.info).forEach((key) => {
            const name = `flight-passenger-${passenger.group}-${index}-${key}`
            const value = passenger.info[key].value
            if (key !== 'saveProps') {
                if (key === 'identityNumberProps'){
                    localStorage.setItem(name, value || '')
                }
                else if (value) {
                    localStorage.setItem(name, value)
                }
            }
        })
    })
}

const setFlightPassengersInLocaleStorageOneByOne = (group, index, type, value) => {
    if (isHasStorage && store.getters._getPageProps.isAutoSave && type !== 'saveProps'){
        const name = `flight-passenger-${group}-${index}-${type}`
        if (type === 'identityNumberProps'){
            localStorage.setItem(name, value || '')
        }
        else if (value) {
            localStorage.setItem(name, value)
        }
    }
}

const setCommunicationValuesInStorage = (key, value) => {
    if (isHasStorage && store.getters._getPageProps.isAutoSave && value){
        localStorage.setItem(`contact-${key}`, value)
    }
}

const setContactInfoByStorageValues = () => {
    if (!isHasStorage) return

    const email = localStorage.getItem('contact-email')
    const phone = localStorage.getItem('contact-phone')
    if (!email && !phone) return

    const contactInfo = JSON.parse(JSON.stringify(store.getters._getContactInformationValues))

    if (email){
        contactInfo.email.value = email
        contactInfo.email.result = true
    }
    if (phone){
        contactInfo.phone.value = phone
        contactInfo.phone.result = true
    }

    store.commit('setContactInformationValues', JSON.parse(JSON.stringify(contactInfo)))
}

const setPassengerInfoWithStoragePassengersValuesForCar = () => {
    if (!isHasStorage) return

    const driverInfo = JSON.parse(JSON.stringify(store.getters._getRentACarDriverFormValues))
    const storagePassengers = getStoragePassengersByPageType('car')
    if (Object.keys(storagePassengers).length > 0){
        Object.entries(storagePassengers.ADT[0]).forEach(([key, value]) => {
            driverInfo[key].value = value
            driverInfo[key].result = true
        })
    }

    store.commit('setRentACarDriverFormValues', driverInfo)
}

const setCarPassengersInLocaleStorage = () => {
    if (!isHasStorage) return

    const driverInfo = store.getters._getRentACarDriverFormValues
    Object.entries(driverInfo).forEach(([key, value]) => {
        if (key !== 'saveProps') {
            const name = `car-passenger-ADT-0-${key}`
            if (key === 'identityNumberProps'){
                localStorage.setItem(name, value.value || '')
            }
            else if (value.value) {
                localStorage.setItem(name, value.value)
            }
        }
    })
}

const setCarPassengersInLocaleStorageOneByOne = (value, key) => {
    if (isHasStorage && store.getters._getPageProps.isAutoSave && key !== 'saveProps'){
        const name = `car-passenger-ADT-0-${key}`
        if (key === 'identityNumberProps'){
            localStorage.setItem(name, value || '')
        }
        else if (value) {
            localStorage.setItem(name, value)
        }
    }
}

const setBusPassengersInLocaleStorageOneByOne = (passengerIndex, key, value) => {
    if (isHasStorage && store.getters._getPageProps.isAutoSave && key !== 'saveProps'){
        if (value || key === 'identityNumberProps') localStorage.setItem(`bus-passenger-${passengerIndex}-${key}`, value)
    }
}

const setBusPassengersInLocaleStorageForPassengerGroup = (passengerInformation, index) => {
    if (isHasStorage && store.getters._getPageProps.isAutoSave){
        Object.entries(passengerInformation).forEach(([key, value]) => {
            if (key !== 'saveProps') {
                const name = `bus-passenger-${index}-${key}`
                if (key === 'identityNumberProps'){
                    localStorage.setItem(name, value.value || '')
                }
                else if (value.value) {
                    localStorage.setItem(name, value.value)
                }
            }
        })
    }
}

const setStoragePassengersValuesInPassengerListForBus = () => {
    const passengerList = store.getters._getBusPassengersFormValues
    if (!isHasStorage || !passengerList) return

    const storagePassengers = getStoragePassengersByPageType('bus')
    passengerList.forEach((passenger, passengerIndex) => {
        const storedPassenger = storagePassengers[passengerIndex];
        if (storedPassenger){
            Object.entries(storedPassenger).forEach(([key, value]) => {
                const passengerInfo = passenger.passengerInformation[key];
                if (key === 'identityNumberProps'){
                    if (value) passengerInfo.value = value
                    else passengerInfo.isNotTurkishPeople = true
                }
                else{
                    passengerInfo.value = value
                }
                passengerInfo.result = true
                passenger.passengerInformation[key] = passengerInfo
            })
        }
    })

    store.commit('setBusPassengersFormValues', [...passengerList])
}

const setHotelOfferGuestsInLocaleStorageWithType = (targetRoomIndex, guestIndex, inputType, value) => {
    if (isHasStorage && store.getters._getPageProps.isAutoSave && inputType !== 'title' && value){
        localStorage.setItem(`hotel-guest-${targetRoomIndex}-${guestIndex}-${inputType}`, value)
    }
}

const setHotelCurrentGuestsInLocaleStorage = (name, surname) => {
    if (isHasStorage && store.getters._getPageProps.isAutoSave){
        if (name) localStorage.setItem('hotel-current-guest-values-name', name)
        if (surname) localStorage.setItem('hotel-current-guest-values-surname', surname)
    }
}

const setReservationGuestValuesFromStorage = (guests) => {
    if (!isHasStorage) return guests

    const tempGuests = JSON.parse(JSON.stringify(guests))
    const name = localStorage.getItem('hotel-current-guest-values-name')
    const surname = localStorage.getItem('hotel-current-guest-values-surname')
    if (name) {
        tempGuests.currentGuest.name.value = name
        tempGuests.currentGuest.name.result = true
    }
    if (surname) {
        tempGuests.currentGuest.surname.value = surname
        tempGuests.currentGuest.surname.result = true
    }

    tempGuests.rooms.forEach((room,roomIndex)=>{
        room.guests.forEach((guest,guestIndex)=>{
            Object.keys(guest).forEach((key) => {
                if (key === 'title') return
                if (localStorage.getItem(`hotel-guest-${roomIndex}-${guestIndex}-${key}`)){
                    guest[key].value = localStorage.getItem(`hotel-guest-${roomIndex}-${guestIndex}-${key}`)
                    guest[key].result = true
                }
            })
        })
    })

    return tempGuests
}

export default {
    setStoragePassengersValuesInPassengerListForFlight,
    setContactInfoByStorageValues,
    setFlightPassengersInLocaleStorage,
    setFlightPassengersInLocaleStorageOneByOne,
    setCommunicationValuesInStorage,
    setPassengerInfoWithStoragePassengersValuesForCar,
    setCarPassengersInLocaleStorage,
    setCarPassengersInLocaleStorageOneByOne,
    setBusPassengersInLocaleStorageOneByOne,
    setBusPassengersInLocaleStorageForPassengerGroup,
    setStoragePassengersValuesInPassengerListForBus,
    setHotelCurrentGuestsInLocaleStorage,
    setHotelOfferGuestsInLocaleStorageWithType,
    setReservationGuestValuesFromStorage,
}
