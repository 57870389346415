import store from "@store/Store.js";

export const saveBillingFormValuesToLocalStorage = () => {
    const tempBillingFormValues = JSON.parse(JSON.stringify(store.getters._getBillingFormValues))
    if (!tempBillingFormValues) return;
    const tempBillingType = store.getters._getBillingType
    delete tempBillingFormValues.saveProps
    if (tempBillingType === 'individual'){
        delete tempBillingFormValues.companyName
        delete tempBillingFormValues.taxAdministration
        delete tempBillingFormValues.taxNumberProps
    }
    else if (tempBillingType === 'corporate'){
        delete tempBillingFormValues.identityNumberProps
        delete tempBillingFormValues.nameProps
    }
    Object.entries(tempBillingFormValues).forEach(([key, value]) => {
        if (key !== 'saveProps'){
            value.result = null
            value.errorMessage = ''
        }
    })
    localStorage.setItem('billing-form-values', JSON.stringify(tempBillingFormValues))
    localStorage.setItem('billing-form-type', JSON.stringify(tempBillingType))
}
