import { createInertiaApp } from '@inertiajs/vue3'
import RootHelpers from "@helpers/Root.js";
import '@helpers/FontAwesomeSetup.js'
import CookieConsent from "@helpers/CookieConsent.js";
//import { registerSW } from 'virtual:pwa-register'

try {
    CookieConsent()
} catch (error) {
    console.error('Cookie consent error:', error)
}

if (typeof window !== 'undefined' && /CriOS|EdgiOS/.test(window.navigator.userAgent)) {
    window.onpageshow = function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    };
}

createInertiaApp({
    progress: RootHelpers.progress,
    resolve: name => RootHelpers.resolve(name),
    setup({ el, App, props, plugin }) {
        RootHelpers.RootApp(App, props, plugin).mount(el);
    },
})

//if (typeof window !== 'undefined'){
//    registerSW({
//        immediate: true,
//        onNeedRefresh() {},
//        onOfflineReady() {},
//    })
//}
