const HeaderVuex = {
    state: {
        headerModalsToggle: {
            langModalOpen: false,
            loginModalOpen: false,
            registerModalOpen: false,
            forgotPasswordModalOpen: false,
        },

        siteMenus: [
            {
                id: 0,
                menuName: 'Uçak Bileti',
                menuRoute: 'flight.home',
                color: '#7db901',
                icon: 'plane-departure'
            },
            {
                id: 1,
                menuName: 'Otel',
                menuRoute: 'hotel.home',
                color: 'rgb(253, 135, 0)',
                icon: 'bed'
            },
            {
                id: 2,
                menuName: 'Otobüs Bileti',
                menuRoute: 'bus.home',
                color: 'rgb(6, 93, 173)',
                icon: 'bus-alt'
            },
            {
                id: 3,
                menuName: 'Araç Kiralama',
                menuRoute: 'rentACar.home',
                color: '#FF3B42',
                icon: 'car'
            },
            {
                id: 4,
                menuName: 'Blog',
                menuRoute: 'blog.home',
                color: '#FF3B42',
                icon: 'blog'
            },
            {
                id: 5,
                menuName: 'İletişim',
                menuRoute: 'contact',
                color: '#FF3B42',
                icon: 'phone'
            },
            {
                id: 6,
                menuName: 'Yardım',
                menuRoute: 'faq.home',
                color: '#FF3B42',
                icon: 'question'
            },
            {
                id: 7,
                menuName: 'Seyahatlerini Yönet',
                menuRoute: 'manageReservation',
                color: '#7db901',
                icon: 'user-check'
            },
            //{
            //    id: 6,
            //    menuName: 'Rezervasyon Sorgula',
            //    menuRoute: 'hotel.home',
            //    color: 'rgb(253, 135, 0)',
            //    icon: 'concierge-bell'
            //},
        ],

        currentUserMenuItems: [
            {
                icon: 'user-circle',
                text: 'Profilim',
                route: 'account.dashboard',
            },
            {
                icon: 'suitcase-rolling',
                text: 'Seyahatlerim',
                route: 'account.trips',
            },
            {
                icon: 'user-friends',
                text: 'Yolcularım',
                route: 'account.passengers',
            },
            {
                icon: ['far', 'plus-square'],
                text: 'Sorgulamax Puanlarım',
                route: 'account.smPoint',
            },
            //{
            //    icon: ['far', 'credit-card'],
            //    text: 'Kayıtlı Kartlarım',
            //    route: 'account.credit-carts',
            //},
            {
                icon: 'file-alt',
                text: 'Fatura Bilgilerim',
                route: 'account.invoiceInformation',
            },
            //{
            //    icon: 'history',
            //    text: 'Geçmiş Aramalarım',
            //    route: 'account.before-search',
            //},
            //{
            //    icon: 'stopwatch',
            //    text: 'Fiyat Alarmlarım',
            //    route: 'account.price-alerts',
            //},
            //{
            //    icon: ['far', 'heart'],
            //    text: 'Favorilerim',
            //    route: 'account.favorites',
            //},
            {
                icon: 'sign-out-alt',
                text: 'Çıkış Yap',
                route: null,
            },
        ],

        headerStepVariables: null,

        mobileMainMenuIsOpen: false,
    },

    mutations: {
        setModalValues(state,values){
            state.headerModalsToggle = values
        },

        setModalValuesToDefault(state){
            state.headerModalsToggle = {
                langModalOpen: false,
                loginModalOpen: false,
                registerModalOpen: false,
                forgotPasswordModalOpen: false,
            }
        },

        setStepVariables(state, page = null){
            if (page) {
                state.headerStepVariables = [
                    {
                        stepNumber: 1,
                        stepText: 'Seyahatini Seç',
                        isCompleted: true,
                    },
                    {
                        stepNumber: 2,
                        stepText: 'Bilgilerini Gir',
                        isCompleted: page === 'payment',
                    },
                    {
                        stepNumber: 3,
                        stepText: 'Ödemeyi Tamamla',
                        isCompleted: false,
                    },
                ]
            }
            else{
                state.headerStepVariables = null
            }
        },

        setMobileMainMenuIsOpen(state, value){
            state.mobileMainMenuIsOpen = value
        }
    },

    getters: {
        _getModalValues: state => state.headerModalsToggle,

        _getGlobalSiteMenus: state => state.siteMenus,

        _getCurrentUserMenuItems: state => state.currentUserMenuItems,

        _getStepVariables: state => state.headerStepVariables,

        _getMobileMainMenuIsOpen: state => state.mobileMainMenuIsOpen
    },
}

export default HeaderVuex;
