import GlobalAxios from "@utils/Api/GlobalAxios.js";

const tripPay = (url, sendData) => {
    return GlobalAxios.Api.post(
        url,
        sendData
    )
}

const getPaymentInstallmentOptions = (data) => {
    return GlobalAxios.Api.post('/pay-service/installment-options', data)
}

export default {
    tripPay,
    getPaymentInstallmentOptions
}
