import GlobalAxios from "@utils/Api/GlobalAxios.js";
import Helpers from "@helpers/Index.js";


const getHotels = (data = null) => {
    if (!data) {
        const queryParams = Helpers.getQueryParams();
        if (queryParams?.is_map){
            queryParams.is_map = queryParams.is_map === 'true'
        }
        return GlobalAxios.Api.post(
            'hotel/availability',
            queryParams,
            {
                headers: {
                    'Accept': 'application/json'
                }
            }
        )
    }

    return GlobalAxios.Api.post(
        'hotel/availability',
        data,
    )
}

export default getHotels
