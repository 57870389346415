

const Geolocation = {
    state: {
        userLocation: null
    },

    mutations: {
        setUserLocation(state, payload) {
            state.userLocation = payload
        }
    },

    getters: {
        _getUserLocation: state => state.userLocation,
    },
}

export default Geolocation;
