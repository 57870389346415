const COOKIE_KEY = 'consent-sorgulamax-custom-cookie';
const COOKIE_EXPIRY_MONTHS = 12;

const CONSENT_STATES = {
    GRANTED: {
        ad_storage: "granted",
        analytics_storage: "granted",
        functionality_storage: "granted",
        security_storage: "granted",
        personalization_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted"
    },
    DENIED: {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        security_storage: "granted",
        personalization_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied"
    }
};

const getCookieValue = (key) => {
    try {
        return document.cookie
            .split(';')
            .map(cookie => cookie.trim())
            .find(cookie => cookie.startsWith(`${key}=`))
            ?.split('=')[1] || null;
    } catch (error) {
        console.error('Cookie parsing error:', error);
        return null;
    }
};

const setConsentCookie = () => {
    try {
        const expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + COOKIE_EXPIRY_MONTHS);
        document.cookie = `${COOKIE_KEY}=granted; expires=${expiryDate.toUTCString()}; path=/; SameSite=Lax`;
    } catch (error) {
        console.error('Error setting consent cookie:', error);
    }
};

const handleConsentClick = () => {
    gtag("consent", "update", CONSENT_STATES.GRANTED);
    setConsentCookie();
    window.removeEventListener('click', handleConsentClick);
};

const cookieConsent = () => {
    const consentValue = getCookieValue(COOKIE_KEY);

    if (consentValue === 'granted') {
        gtag("consent", "default", CONSENT_STATES.GRANTED);
    } else {
        gtag("consent", "default", CONSENT_STATES.DENIED);
        window.addEventListener('click', handleConsentClick);
    }
};

export default cookieConsent;
