import GlobalAxios from "@utils/Api/GlobalAxios.js";
import Helpers from "@helpers/Index.js"

const autocomplete =  (searchValue) => {
    return GlobalAxios.Api.get(
        `/autocomplete/flight-ticket?term=${searchValue}`,
    )
}

const getFlight = () => {
    return GlobalAxios.Api.post(
        '/flight-ticket/get-flights',
        Helpers.getQueryParams(),
        {
            headers: {
                'Accept': 'application/json'
            }
        }
    )
}

const selectFlight = (data) => {
    return GlobalAxios.Api.post(
        '/flight-ticket/select-flight',
        {
            ...data
        }
    )
}

const reservation = (data) => {
    return GlobalAxios.Api.post(
        '/flight-ticket/reservation',
        {
            ...data
        }
    )
}

const getBestPrices = (sendData) => {
    return GlobalAxios.Api.post(
        '/flight-ticket/get-best-prices',
        {
            ...sendData
        }
    )
}

export default {
    autocomplete,
    getFlight,
    selectFlight,
    reservation,
    getBestPrices,
}
