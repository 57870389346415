import {Ziggy} from "../../../ziggy.js"

//const phoneNumberWithoutFormat = typeof process !== 'undefined'
//    ? process.env.VITE_SITE_PHONE_NUMBER
//    : import.meta.env.VITE_SITE_PHONE_NUMBER
//
//const formatPhoneNumber = () => {
//    const cleaned = phoneNumberWithoutFormat.replace(/\D/g, '');
//    const number = cleaned.startsWith('90') ? cleaned.slice(2) : cleaned;
//    return number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
//};
//
//const callCenterNumber = formatPhoneNumber()


const SiteSettings = {
    state: {
        callCenterNumber: '850 335 00 00',

        socialMedia: [
            {
                name: 'facebook',
                url: 'https://www.facebook.com/sorgulamaxcom/',
            },
            {
                name: 'twitter',
                url: 'https://x.com/sorgulamax_',
            },
            {
                name: 'instagram',
                url: 'https://www.instagram.com/sorgulamax/',
            }
        ],

        appLinks: {
            appStore: '',
            playStore: '',
            //playStore: {
            //    url: 'https://srgl.mx/app',
            //    description: '',
            //    name: '',
            //    applicationCategory: '',
            //    datePublished: '',
            //    author: {
            //        "@type": "Person",
            //        "name": "OLIMTUR LTD. STI.",
            //        "url": "https://srgl.mx/app"
            //    },
            //    "aggregateRating": {
            //        "@type": "AggregateRating",
            //        "ratingValue": "4.519999980926514",
            //        "ratingCount": "897",
            //        "worstRating": 1,
            //        "bestRating" : 5,
            //    },
            //    image: '',
            //},
            appGallery: '',
        },

        companyInfo: {
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": '+908503350000',
                "contactType": "customer service",
                "areaServed": "TR",
                "availableLanguage": "Turkish"
            },
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "Kocaeli",
                "addressCountry": "TR",
                "postalCode": "41740",
                "streetAddress": "Güney, Sancak Cd. No: 2 Kat: 2, 41740 Körfez/Kocaeli"
            },
            "email": typeof process !== 'undefined' ? process.env.VITE_SITE_EMAIL : import.meta.env.VITE_SITE_EMAIL,
            "faxNumber": "",//TODO Doldur
            "url": Ziggy.url,
            "name": "Sorgulamax",
            "alternateName": "Uçak bileti",
            "logo": `${Ziggy.url}/build/static/images/sm-logo-800px.jpg`,
            "image": `${Ziggy.url}/build/static/images/sm-logo-800px.jpg`,
            "description": "Sorgulamax'ta en avantajlı uçak bileti, otobüs bileti, otel rezervasyonu, araç kiralama fiyatlarını karşılaştırın ve seyahatlerinizde en uygun fiyatı bulun.",
            "vatID": "FR12345678901",
            "iso6523Code": "0199:724500PMK2A2M1SQQ228"
        }
    },

    getters: {
        _getCallCenterNumber: state => state.callCenterNumber,

        _getSiteInfos: state => ({
            callCenterNumber: state.callCenterNumber,
            socialMedia: state.socialMedia,
            appLinks: state.appLinks,
            companyInfo: {
                ...state.companyInfo,
                url: Ziggy.url,
            },
        }),

        _getAppInfo: state => state.appLinks,
    },
}

export default SiteSettings;
