import ListPagesSearchResult from "@store/Modules/List/ListPagesSearchResult.js";
import BestPrices from "@store/Modules/List/BestPrices.js";
import ShortingOptions from "@store/Modules/List/ShortingOptions.js";
import HotelMapModalValues from "@store/Modules/List/HotelMapModalValues.js";

const ListPagesVuex = {
    state: {

    },

    mutations: {

    },

    getters: {

    },

    modules: {
        ListPagesSearchResult,
        BestPrices,
        ShortingOptions,
        HotelMapModalValues,
    }
}

export default ListPagesVuex
