import store from "@store/Store.js";
import helpers from "@helpers/Index.js";
import Api from "@utils/Api/Index.js";

const getLocationOrCurrentLocation = (departurePlace) => {
    if (departurePlace.location_id){
        return {
            location_id: departurePlace.location_id,
        }
    }
    return {
        lat: departurePlace.lat,
        lng: departurePlace.lng,
    }
}

export const createSendDataForListPage = (activityId = null) => {
    const {locationValues, dateValues, guestSize} = store.getters._getHotelTabSearchForm
    const sendData = {
        ...getLocationOrCurrentLocation(locationValues.departurePlace),
        start_date: getDate(dateValues.departureDate),
        end_date: getDate(dateValues.landingDate),
        rooms: {}
    }

    guestSize.forEach((room,index) => {
        sendData.rooms[index] = {
            adults: room.adultValue,
            children_ages: [...room.childrenAges],
        }
    })

    const options = store.getters._getPageProps?.searchForm?.options || null
    if (options){
        sendData.options = options
    }
    if (store.getters._getAgentCode){
        sendData.agent_code = store.getters._getAgentCode
    }
    if (activityId){
        sendData.id = activityId
    }
    return sendData
}

const filtersPushHistory = (filters) => {
    if (store.getters._getHotelMapModalActiveStatus) return;
    delete filters.page
    const url = `${window.location.href.split('?')[0]}?${helpers.flattenParams('', filters)}`
    window.history.pushState(window.history.state, '', url)
    if (helpers.isIosChromeAndEdgeBrowser()){
        window.history.replaceState(window.history.state, null, url)
    }
}

const handleHotelsResponse = (hotelResponse, isInfinityScroll, sendData) => {
    let responseData = hotelResponse.data.data
    if (isInfinityScroll){
        responseData = {
            ...responseData,
            hotels: [
                ...store.getters._getSearchResult.hotels,
                ...responseData.hotels
            ]
        }
    }
    else {
        filtersPushHistory(sendData)
    }

    if (sendData.is_map){
        store.commit('setMapModalSearchData', responseData)
        store.commit('setHotelMapFilters', responseData.options.filters)
    }
    else{
        store.commit('setSearchResult', responseData)
        store.commit('setHotelListFilters', responseData.options.filters)
    }
    store.commit('setHotelSortingOptions', responseData.options.sorting)
}

let isPendingHotelInfinityScrollResponse = false

export const handleHotelListFiltersAndShortingOption = (isInfinityScroll = false) => {
    let serviceIsAvailable = !(isInfinityScroll && isPendingHotelInfinityScrollResponse)

    if (serviceIsAvailable){
        if (!isInfinityScroll) store.commit('setFiltersProcessingStatus', true)
        else isPendingHotelInfinityScrollResponse = true

        const sendData = createSendDataForHotelListFilters(isInfinityScroll)
        if (store.getters._getHotelMapModalActiveStatus){
            sendData.is_map = true
        }
        Api.Hotel.getHotels(sendData)
            .then(hotelResponse => {
                store.commit('setTargetActivityId', hotelResponse?.data?.data?.id || null)
                handleHotelsResponse(hotelResponse, isInfinityScroll, sendData)
            })
            .catch(error => {
                helpers.apiErrorHanding.initialErrorsHandle(error.response.data.errors)
            })
            .finally(()=> {
                store.commit('setFiltersProcessingStatus', false)
                isPendingHotelInfinityScrollResponse = false
            })
    }
}

const createSendDataForHotelListFilters = (isInfinityScroll) => {
    const isMap = store.getters._getHotelMapModalActiveStatus
    const searchResult = isMap
        ? store.getters._getMapModalSearchData
        : store.getters._getSearchResult
    const sendData = {
        ...createSendDataForListPage(searchResult?.id),
        options: {
            filters: {},
        },
    }

    if (!isMap && isInfinityScroll) {
        const {current_page, last_page} = searchResult.pagination
        sendData.page = current_page < last_page
            ? current_page + 1
            : last_page
    }

    const selectedSortingOption = store.getters._getSortingOptions.hotel.find(option => option.selected)
    if (selectedSortingOption?.key) sendData.options.sorting = selectedSortingOption.key

    const selectedFilters = isMap
        ? store.getters._getHotelMapSelectedFilters
        : store.getters._getHotelListSelectedFilters

    if (selectedFilters.length){
        selectedFilters.forEach(filter => {
            if (filter.type === 'min-max'){
                const {min, max} = filter.value
                if (min || max) sendData.options.filters.price = {}
                if(min) sendData.options.filters.price.min = min
                if(max) sendData.options.filters.price.max = max
            }
            else if (filter.type === 'input'){
                sendData.options.filters[filter.key] = filter.value
            }
            else {
                sendData.options.filters[filter.key] = filter.variables.map(item => item.key)
            }
        })
    }

    return sendData
}

const getDate = (dateObj) => `${dateObj.year}-${dateObj.monthNumber}-${dateObj.dayNumber}`
