import {createSSRApp, h} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Api from "@utils/Api/Index.js"
import helpers, {createCdnUrl} from "@helpers/Index.js";
import store from '@store/Store.js'
import * as Sentry from "@sentry/vue";
import * as SentryBrowser from "@sentry/browser";
import {Link} from "@inertiajs/vue3";

const sentryReportsForHydration = () => {
    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;
    const hydrationRegex = /hydration/i;

    console.error = function (...args) {
        if (hydrationRegex.test(args.join(' '))) {
            Sentry.captureMessage(args.join(' '), 'warning');
        }
        originalConsoleError.apply(console, args);
    };

    console.warn = function (...args) {
        if (hydrationRegex.test(args.join(' '))) {
            Sentry.captureMessage(args.join(' '), 'warning');
        }
        originalConsoleWarn.apply(console, args);
    };
}

const createSentry = (App) => {
    Sentry.init({
        App,
        dsn: helpers.getEnv('VITE_SENTRY_LARAVEL_DSN'),
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay(),
        ],
        tracesSampleRate: helpers.getEnv('VITE_SENTRY_TRACES_SAMPLE_RATE'),
        tracePropagationTargets: helpers.getEnv('VITE_TRACE_PROPAGATION_TARGETS'),
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event) {
            try{
                const userAgent = navigator.userAgent;
                const botRegex = /bot|googlebot|crawler|spider|robot|crawling|bingbot|adsbot|slurp|duckduckbot|baiduspider|yandexbot|sogou|exabot|facebot|ia_archiver|mediapartners/i;
                return botRegex.test(userAgent) ? null : event;
            }
            catch (error) {
                return event;
            }
        },
    });
};

const progress = {
    delay: 200,
    color: 'var(--primary-color)',
    includeCSS: true,
    showSpinner: false,
}

const pageCache = {};

const resolve = async (name) => {
    if (pageCache[name]) {
        return pageCache[name];
    }

    try {
        const pages = import.meta.glob('../../Pages/**/*.vue');
        const page = await pages[`../../Pages/${name}.vue`]();
        pageCache[name] = page;
        return page;
    } catch (error) {
        if (typeof window !== "undefined") {
            SentryBrowser.captureException(error);
            alert("Güzel haber!\nSorgulamax.com'un yeni bir sürümü var.\nİşleminize devam etmek için sayfayı yenilemeniz gerekmektedir.");
        }
    }
};

const RootApp = (App, props, plugin, isSSR = false) => {
    const RootApp = createSSRApp({render: () => h(App, props)});
    RootApp.use(plugin);
    RootApp.use(store);
    RootApp.component("FontAwesomeIcon", FontAwesomeIcon);
    RootApp.component("InertiaLink", Link);
    RootApp.config.globalProperties.$api = Api;
    RootApp.config.globalProperties.route = helpers.routeHandler;
    RootApp.config.globalProperties.helpers = helpers;
    RootApp.config.globalProperties.cdn = createCdnUrl;
    if(!isSSR && helpers.getEnv('VITE_APP_ENV') !== "local") {
        createSentry(RootApp)
        sentryReportsForHydration()
    }

    return RootApp;
}

export default {
    RootApp,
    resolve,
    progress,
}
