import GlobalAxios from "@utils/Api/GlobalAxios.js";
import store from '@store/Store'
import helpers from "@helpers/Index.js";

const logout = () => {
    GlobalAxios.Auth.get('/cikis-yap')
        .then(getProfileResponse => {
            if (getProfileResponse.status === 204){
                store.commit('setUser', null)
                store.commit('setMobileMainMenuIsOpen', false)
                showMessage({status: true, message: 'Çıkış işlemi başarıyla yapıldı.'})
            }
            else{
                showMessage({status: false, message: 'Çıkış işlemi yapılamadı. Lütfen daha sonra tekrar deneyiniz.'})
            }
        })
        .catch(() => {
            showMessage({status: false, message: 'Çıkış işlemi yapılamadı. Lütfen daha sonra tekrar deneyiniz.'})
        })
}

const showMessage = (messageValues) => {
    store.dispatch('_addMiniInformationPopupItem', messageValues)
}

const sendResetPasswordLink = (email) => {
    return GlobalAxios.Api.post(
        '/user/forgot-password',
        {
            email: email,
        }
    )
}

const passwordReset = (email, token, password, password_confirmation) => {
    return GlobalAxios.Api.post(
        '/user/reset-password',
        {
            email: email,
            password: password,
            password_confirmation: password_confirmation,
            token: token,
        },
    )
}

const login = (data, pageType = '') => {
    return GlobalAxios.Auth.post('/giris-yap', data)
        .then(loginResponse => {
            if (loginResponse.status === 200 || loginResponse.status === 204 || loginResponse.status === 302) {
                store.commit('setMobileMainMenuIsOpen', false)
                if (loginResponse.data.dashboard_url) {
                    window.location = loginResponse.data.dashboard_url
                    helpers.localStorage.setIsAuthenticated('true')
                }
                else {
                    if (typeof window !== 'undefined') window.gtag('event', 'login')
                    getUserInformation()
                }
            }
            else{
                store.commit('_addMiniInformationPopupItem', {status: false, message: 'Giriş Yapılamadı! Lütfen e-posta adresinizi ve şifrenizi kontrol ediniz.'})
            }
            if (pageType !== 'passwordReset'){
                store.commit('setModalValuesToDefault')
            }
            return true
        })
        .catch((error) => {
            helpers.apiErrorHanding.errorHandle(error)
            helpers.localStorage.setIsAuthenticated('false')
            return false
        })
}

const getUserInformation = () => {
    GlobalAxios.Api.get('/user')
        .then(getProfileResponse => {
            if (getProfileResponse.status === 200){
                store.commit("setUser", getProfileResponse.data.data)
            }
            else{
                store.commit("setUser", null)
            }
        })
        .catch(() => {
            store.commit("setUser", null)
        })
}

const updateUserInformation = (sendData) => {
    return GlobalAxios.Api.post(
        '/user',
        sendData
    )
}

const deleteAccount = (password) => {
    return GlobalAxios.Api.delete(
        '/user',
        {
            data: {
                password: password
            },
        },
    )
}

const register = (userValues) => {
    return GlobalAxios.Api.post('/user/register', userValues)
        .then((response) => {
            if (response.status === 200) {
                if (typeof window !== 'undefined') window.gtag('event', 'sign_up')
                store.commit('setMobileMainMenuIsOpen', false)
                login({
                    email: userValues.email,
                    password: userValues.password,
                    remember: false
                })
            }
            else {
                store.dispatch("_addMiniInformationPopupItem", {
                    status: false,
                    message: 'Giriş Yapılamadı! Lütfen e-posta adresinizi ve şifrenizi kontrol ediniz.'
                })
            }
            return true
        })
        .catch((error) => {
            helpers.apiErrorHanding.errorHandle(error)
            return false
        })
}

const passengerList = () => {
    GlobalAxios.Api.get('/user/passengers')
        .then(response => {
            const tempPageProps = store.getters._getPageProps
            tempPageProps.passengerList = response.data.data
            store.commit('setPageProps', tempPageProps)
        })
        .catch(error => {
            helpers.apiErrorHanding.errorHandle(error)
        })
}

const getTrips = (tripBy = '', anotherProps = []) => {
    const path = tripBy
        ? `/user/trips?trip_by=${tripBy}${anotherProps.length ? '&' + anotherProps.join('&') : ''}`
        : '/user/trips'
    return GlobalAxios.Api.get(path)
}

const deletePassenger = (passengerId) => {
    return GlobalAxios.Api.delete(`/user/passengers/${passengerId}`)
}

const deleteBillingInfo = (billingId) => {
    return GlobalAxios.Api.delete(`/user/invoice-informations/${billingId}`)
}

const getBillingInfo = () => {
    return GlobalAxios.Api.get(`/user/invoice-informations`)
}


export default {
    logout,
    sendResetPasswordLink,
    passwordReset,
    login,
    getUserInformation,
    deleteAccount,
    register,
    updateUserInformation,
    passengerList,
    getTrips,
    deletePassenger,

    deleteBillingInfo,
    getBillingInfo
}
