import GlobalAxios from "@utils/Api/GlobalAxios.js";

const autocompleteLog = ({service, term, key, order}) => {
    if (service && term && key && typeof order === 'number') {
        GlobalAxios.Api.post(`/autocomplete/log?service=${service}&term=${term}&key=${key}&order=${order}`)
            .then(response => {})
            .catch(error => {});
    }
}

export default {
    autocompleteLog
}
