import helpers from '@helpers/Index.js'

const BillingForm = {
    state: {
        billingFormValues: null,

        billingType: 'individual',
    },

    mutations: {
        setBillingFormValuesByInputType(state, { type, value }){
            if ('isUpdate isSave'.includes(type)) state.billingFormValues.saveProps[type] = { value }
            else {
                state.billingFormValues[type] = value
                helpers.localStorage.billing.saveBillingFormValuesToLocalStorage()
            }
        },

        setBillingFormValues(state, values){
            state.billingFormValues = values
            helpers.localStorage.billing.saveBillingFormValuesToLocalStorage()
        },

        setBillingValuesToEmpty(state){
            state.billingFormValues =  {
                nameProps: {
                    placeholder: 'İsim ve Soyisim',
                    placeholderType: 'top',
                    errorMessage: '',
                    result: null,
                    value: '',
                },
                companyName: {
                    placeholder: 'Firma Adı',
                    placeholderType: 'top',
                    errorMessage: '',
                    result: null,
                    value: '',
                },
                taxAdministration: {
                    placeholder: 'Vergi Dairesi',
                    placeholderType: 'top',
                    errorMessage: '',
                    result: null,
                    value: '',
                },
                identityNumberProps: {
                    placeholder: 'TC Kimlik No',
                    isNotTurkishPeople: false,
                    placeholderType: 'top',
                    errorMessage: '',
                    result: null,
                    value: '',
                },
                taxNumberProps: {
                    placeholder: 'Vergi Numarası',
                    isNotTurkishPeople: false,
                    placeholderType: 'top',
                    errorMessage: '',
                    result: null,
                    value: '',
                },
                saveProps: {
                    isSave: {
                        value: true
                    }
                }
            }
        },

        setBillingType(state, type){
            state.billingType = type
        }
    },

    getters: {
        _getBillingFormValues: state => state.billingFormValues,

        _getBillingType: state => state.billingType,

        _getBillingFormValuesForSend: state => (isAuth = false, isAgent = false) => {
            if (!state.billingFormValues) return null
            const [type, valueMappings] = getBillingVariables(state.billingType)
            return {
                type,
                ...saveBillingInfo(state.billingFormValues.saveProps, isAuth, isAgent),
                ...Object.fromEntries(Object.entries(valueMappings).map(([key, value]) => [value, state.billingFormValues[key].value]))
            }
        },

        _getIsHasBillingInfo: state => state.billingFormValues ? Object.values(state.billingFormValues).some(item => item.value) : false,
    }
}

const getBillingVariables = (type) => {
    return type === 'individual' ?
        ['individual', {'nameProps': 'name', 'identityNumberProps': 'tax_id'}] :
        ['corporate', {'companyName': 'name', 'taxNumberProps': 'tax_id', 'taxAdministration': 'tax_office'}]
}

const saveBillingInfo = (saveProps, isAuth, isAgent) => {
    if (!isAuth || !isAgent) return {}
    if (saveProps.isSave?.value) return {add_to_invoice_list: true}
    else if (saveProps.isUpdate?.value) return {update_from_invoce_list: true, ref_id: saveProps.isUpdate.id}
    else return {}
}

export default BillingForm
