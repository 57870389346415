import User from "@utils/Api/User/Index";
import Flight from "@utils/Api/Flight/Index";
import TripPay from "@utils/Api/TripPay";
import Car from "@utils/Api/Car/Index.js";
import PNRQuery from "@utils/Api/PNRQuery.js"
import Blog from "@utils/Api/Blog/Index.js"
import Bus from "@utils/Api/Bus/Index.js"
import Hotel from "@utils/Api/Hotel/Index.js"
import Contact from "@utils/Api/Contact.js";
import Contracts from "@utils/Api/Contracts.js";
import Newsletter from "@utils/Api/Newsletter.js";
import Log from "@utils/Api/Log.js";

export default {
    User,
    Flight,
    TripPay,
    Car,
    PNRQuery,
    Blog,
    Bus,
    Hotel,
    Contact,
    Contracts,
    Newsletter,
    Log,
}
